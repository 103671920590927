import React, { PropsWithChildren, Reducer, forwardRef, useContext, useEffect, useImperativeHandle, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { LoginUserContext } from "../../../../RAFAuthentication/RAFLoginUserContextProvider";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import { isNotNullAndUndefined, isNullOrUndefined } from "../../../../RAFComponents/helpers/utils";
import { WorkspaceLeftItemsContext } from "../../../../RAFModules/Common/Providers/WorkspaceContextProvider";
import { MenuCategory } from "../../../../constants/MenuConstant/RAFMenuCategory";
import { navigateToDashboard } from "../../../../helpers/ACutils";
import RAFProfileCard from "../../../../helpers/RAFProfileCard/RAFProfileCard";
import sidebarToggleImg from "./../../../../assets/sidebar_toggle_img.png";

const productLogoReversal = `${window.location.origin}/Content/img/workesio logo reversal.png`;
const productLogo = `${window.location.origin}/Content/img/workesio logo.png`;
const productShortLogoReversal = `${window.location.origin}/Content/img/workesio logo reversal short.png`;
const productShortLogo = `${window.location.origin}/Content/img/workesio logo reversal short.png`;

interface IProps {
    theme: "White" | "Dark";
    onClickSidebarToggleBtn: () => void;
    activeCurrentPathUpdated: () => void;
    toggleclick: () => void;
}

interface IState {
    currentPath: string;
    selectExpandStateByID: string;
}

const NavigationMenuTreeViewHeaderContent = forwardRef(
    function NavigationMenuTreeViewHeaderContent({ ...props }: PropsWithChildren<IProps>,
        ref
    ) {
        let navigate = useNavigate();

        const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
            (state, newState) => ({ ...state, ...newState }),
            {
                currentPath: null,
                selectExpandStateByID: null
            }
        );

        //check if the current path is changed then update the selected item in left navigation :JD
        useEffect(() => {
            const currentPath = window.location.pathname.toLowerCase();
            if (state.currentPath !== currentPath) {
                activeCurrentPathUpdated();
            }
        }, [navigate]);

        const activeCurrentPathUpdated = () => {
            if (props.activeCurrentPathUpdated) {
                props.activeCurrentPathUpdated();
            }
        };

        //store the current left selected item path and selectExpandStateByID in state :JD
        useImperativeHandle(ref, () => ({
            updateTreeViewSelectExpandStateByID(selectExpandStateByID, currentPath) {
                if (state.selectExpandStateByID !== selectExpandStateByID || state.currentPath !== currentPath) {
                    setState({ selectExpandStateByID, currentPath });
                }
            },
        }));

        const loginUserContext = useContext(LoginUserContext);

        const workspaceLeftItemsContext = useContext(WorkspaceLeftItemsContext);

        const currentMenuCategory =
            isNotNullAndUndefined(workspaceLeftItemsContext) &&
                isNotNullAndUndefined(workspaceLeftItemsContext.currentMenuCategory)
                ? workspaceLeftItemsContext.currentMenuCategory
                : MenuCategory.Default;


        const onChangeMenuCategory = (selectedMenu) => {
            if (selectedMenu !== currentMenuCategory) {
                setTimeout(() => {
                    workspaceLeftItemsContext.updateCurrentWorkspaceLeftMenuItems();
                }, 50);
            }
        };

        const backToMainMenu = () => {
            // setSessionStorage(
            //     RMStorageKey.recentClickedMenu,
            //     true,
            //     JSON.stringify([MenuCategory.Default])
            // );
            navigateToDashboard(navigate);
            onChangeMenuCategory(MenuCategory.Default);
        };

        const onDoubleClickProductLogo = () => {
            const canImpersonateUser = loginUserContext.canImpersonateUser;
            const isImpersonatedUser = loginUserContext.isImpersonatedUser;
            if (canImpersonateUser || isImpersonatedUser) {
                navigate("/switchtenant");
            }
        };

        return (
            <>
                <div className="brandLogoDiv raf-lg d-flex justify-content-center align-items-center">
                    <img
                        src={props.theme === "White" ? productLogo : productLogoReversal}
                        alt="Product Logo"
                        className="e-sidebar__treeview__logo pointer full_logo"
                        onDoubleClick={onDoubleClickProductLogo}
                    />
                    <img
                        src={
                            props.theme === "White"
                                ? productShortLogo
                                : productShortLogoReversal
                        }
                        alt="Product Logo"
                        className="e-sidebar__treeview__logo pointer short_logo"
                        onDoubleClick={onDoubleClickProductLogo}
                    />
                    <div style={{ right: "1rem" }} className="position-absolute">
                        <RAFButtonComponent
                            className="e-outline square-btn e-sidebar__treeview__toggleBtn"
                            id="e-sidebar__toggleBtn__collapse"
                            onClick={() => props.toggleclick()}
                            btnContentTemplate={
                                <img
                                    src={sidebarToggleImg}
                                    alt="Sidebar toggle button"
                                    width={16}
                                />
                            }
                        ></RAFButtonComponent>
                        <RAFButtonComponent
                            className="e-outline square-btn e-sidebar__treeview__toggleBtn hidden"
                            onClick={() => props.onClickSidebarToggleBtn()}
                            id="e-sidebar__toggleBtn__expand"
                            btnContentTemplate={
                                <img
                                    src={sidebarToggleImg}
                                    alt="Sidebar toggle button"
                                    width={16}
                                />
                            }
                        ></RAFButtonComponent>
                    </div>
                </div>
                <div className="e-sidebar__treeview__header" key={currentMenuCategory}>
                    {isNullOrUndefined(currentMenuCategory) ||
                        currentMenuCategory === MenuCategory.Default ||
                        currentMenuCategory === MenuCategory.ContentManagement ? (
                        <div className="e-sidebar__profileCardDiv w-100">
                            <RAFProfileCard
                                arrowTipClass="e-tip-left"
                                uiMode="fullBtn"
                                onUpdatMenuCategory={(menu) => onChangeMenuCategory(menu)}
                            />
                        </div>
                    ) : (
                        <div
                            className="pointer text-white e-sidebar__treeview__backBtn"
                            onClick={() => backToMainMenu()}
                        >
                            <div className="row gx-2">
                                <div className="col-auto">
                                    <i className="px-1 fass fa-arrow-left-long"></i>
                                </div>
                                <div className="col e-sidebar__treeview__backBtn__text">
                                    Back to Main Menu
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    });

export default React.memo(NavigationMenuTreeViewHeaderContent);
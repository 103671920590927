export enum RAFModuleName {
    Account = 'Account',
    Contact = 'Contact',
    Deal = 'Deal',
    Ticket = 'Ticket',
    ContentLibrary = 'Content Library',
    Documents = 'Documents',
    EmailAttachment = 'Email Attachment',
    Email = 'Email',
    Lead = 'Lead',
    User = 'User',
    Complaint = 'Complaint',
    BusinessProcessStep = 'Business Process Step',
    BusinessProcess = 'Business Process',
    Task = "Task",
    ServiceTransaction = "Service Transaction",
    Activity = "Notes",
    Employee = "Employee",
}

export enum RAFEntityName {
    Account = 'account',
    Contact = 'contact',
    Deal = 'deal',
    Ticket = 'ticket',
    Complaint = 'complaint',
    Task = 'task',
    ServiceTransaction = 'service_transaction',
    ShiftOffer = 'shift_offer',
    BusinessProcess = 'business_process',
    BusinessProcessStep = 'business_process_step',
    BusinessProcessTemplate = 'business_process_template',
    //BusinessProcessType = 'business_process_type',
    BusinessProcessType = 'category',
    Category = 'category',
    BusinessProcessStepTemplate = 'business_process_step_template',
    BusinessProcessStage = 'business_process_stage',
    BusinessRule = 'business_rule',
    User = 'user',
    Employee = 'employee',
    EmployeeLeave = 'employee_leave',
    Role = 'permission_group',
    ContentLibrary = 'content_library',
    VersionHistory = 'version_history',
    Email = 'email',
    Team = 'team',
    UserSignature = 'user_signature',
    Contract = 'contract',
    FormLibrary = 'form_library',
    FormLibraryStatic = 'form_library_static',
    FormLibraryAdmin = 'form_library_admin',
    Entity = 'entity',
    Template = 'template',
    Portal = 'portal',
    PortalItem = 'portal_item',
    RecordCategory = 'record_category',
    City = 'city',
    State = 'state',
    Country = 'country',
    Note = 'note',
    Document = 'document',
    DataList = 'datalist',
    FormData = 'form_data',
    Report = 'report',
    Dashboard = 'dashboard',
    View = 'view',
    BusinessRuleTrigger = 'business_rule_trigger',
    PageLayout = 'pagelayout',
    UserFocus = 'user_focus',
    DealStage = 'deal_stage',
    Department = 'department',
    Location = 'location',
    Product = 'product',
    Suggestion = 'suggestion',
    Import = 'import',
    TaskTitle = 'task_title',
    ExtraInfoJson = 'extra_info_json',
    Activity = 'activity',
    UserFeed = 'user_feed',
    StandardizedLibrary = 'standardized_library',
    Tag = 'tag',
    Register = 'shift_log',
    PriceList = 'pricelist',
    TenantSettings = 'tenant_settings',
    AvailabilitySchedule = 'availability_schedule',
    FormSchedule = 'form_schedule',
    SignInRegister = 'sign_in_register',
    ProcessLibrary = 'process_library',
    AcknowledgementTracking = 'acknowledgement_tracking',
    ProcessData = 'process_data',
    FormLibraryData = 'form_library_data',
}

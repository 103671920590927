const DOCUMENTREAD = "content_library::Read";
const DOCUMENTCREATE = "content_library::Add";
const DOCUMENTMANAGE = "content_library::Manage";
const DOCUMENTUPDATE = "content_library::Update";
const DOCUMENTDELETE = "content_library::Delete";
const DOCUMENTIMPORT = "content_library::Import";
const DOCUMENTADDVIEW = "content_library::AddView";

const DOCUMENTACKNOWLEDGEMENTTRACKINGVIEW = "acknowledgement_tracking::viewacknowledgement";
const DOCUMENTACKNOWLEDGEMENTTRACKINGSEND = "acknowledgement_tracking::sendacknowledgementrequest";

const MANAGEVIEWPRIVATE = "ManageView::Private";
const MANAGEVIEWSHARED = "ManageView::Shared";
const MANAGEVIEWPUBLIC = "ManageView::Public";

const TASKREAD = "Task::Read";
const TASKManage = "Task::Manage";
const TASKDELETE = "Task::Delete";
const TASKCOMPLETE = "Task::Complete";
const TASKEDITCOMPLETEDTASKS = "Task::EditCompletedTasks";
const TASKViewDocuments = "Task::ViewDocuments";
const TASKManageDocuments = "Task::ManageDocuments";
const TASKDeleteDocuments = "Task::DeleteDocuments";

const EMAILREAD = "Email::Read";
const EMAILCREATE = "Email::Add";
const EMAILUPDATE = "Email::Update";
const EMAILDELETE = "Email::Delete";
const EMAILIMPORT = "Email::Import";
const EMAILADDVIEW = "Email::AddView";

const COMMENTSREAD = "Activity::Read";
const COMMENTSCREATE = "Activity::Add";
const COMMENTSUPDATE = "Activity::Update";
const COMMENTSDELETE = "Activity::Delete";

const USERREAD = "User::Read";
const USERCREATE = "User::Add";
const USERUPDATE = "User::Update";
const USERDELETE = "User::Delete";

const TeamREAD = "Team::Read";
const TeamCREATE = "Team::Add";
const TeamUPDATE = "Team::Update";
const TeamDELETE = "Team::Delete";

const ROLEREAD = "permission_group::Read";
const ROLECREATE = "permission_group::Add";
const ROLEUPDATE = "permission_group::Update";
const ROLEDELETE = "permission_group::Delete";

const TemplateREAD = "Template::Read";
const TemplateCREATE = "Template::Add";
const TemplateUPDATE = "Template::Update";
const TemplateDELETE = "Template::Delete";

const SignatureRead = "Signature::Read";
const ShowAdministrationRead = "ShowAdministration::Read";

const AnalyticsRead = "Analytics::Read";
const AnalyticsManage = "Analytics::Manage";
const AnalyticsDelete = "Analytics::Delete";


export const PermissionConstants = {
    DocumentRead: DOCUMENTREAD,
    DocumentCreate: DOCUMENTCREATE,
    DocumentManage: DOCUMENTMANAGE,
    DocumentUpdate: DOCUMENTUPDATE,
    DocumentDelete: DOCUMENTDELETE,
    DocumentImport: DOCUMENTIMPORT,
    DocumentAddView: DOCUMENTADDVIEW,

    DocumentAcknowledgementTrackingView: DOCUMENTACKNOWLEDGEMENTTRACKINGVIEW,
    DocumentAcknowledgementTrackingSend: DOCUMENTACKNOWLEDGEMENTTRACKINGSEND,

    ManageViewPrivate: MANAGEVIEWPRIVATE,
    ManageViewShared: MANAGEVIEWSHARED,
    ManageViewPublic: MANAGEVIEWPUBLIC,

    //task_dashboardRead: TASK_DASHBOARDREAD,
    TaskRead: TASKREAD,
    //TaskCreate: TASKCREATE,//not used
    //TaskUpdate: TASKUPDATE,//not used
    TaskManage: TASKManage,
    TaskDelete: TASKDELETE,
    TaskComplete: TASKCOMPLETE,
    TaskEditCompletedTasks: TASKEDITCOMPLETEDTASKS,
    //TaskImport: TASKIMPORT,
    //TaskAddView: TASKADDVIEW,
    TaskViewDocuments: TASKViewDocuments,
    TaskManageDocuments: TASKManageDocuments,
    TaskDeleteDocuments: TASKDeleteDocuments,

    emailRead: EMAILREAD,
    EmailRead: EMAILREAD,
    EmailCreate: EMAILCREATE,
    EmailUpdate: EMAILUPDATE,
    EmailDelete: EMAILDELETE,
    EmailImport: EMAILIMPORT,
    EmailAddView: EMAILADDVIEW,

    CommentsRead: COMMENTSREAD,
    CommentsCreate: COMMENTSCREATE,
    CommentsUpdate: COMMENTSUPDATE,
    CommentsDelete: COMMENTSDELETE,

    userRead: USERREAD,
    UserRead: USERREAD,
    UserCreate: USERCREATE,
    UserUpdate: USERUPDATE,
    UserDelete: USERDELETE,

    TeamRead: TeamREAD,
    TeamCreate: TeamCREATE,
    TeamUpdate: TeamUPDATE,
    TeamDelete: TeamDELETE,

    roleRead: ROLEREAD,
    RoleRead: ROLEREAD,
    RoleCreate: ROLECREATE,
    RoleUpdate: ROLEUPDATE,
    RoleDelete: ROLEDELETE,

    TemplateRead: TemplateREAD,
    TemplateCreate: TemplateCREATE,
    TemplateUpdate: TemplateUPDATE,
    TemplateDelete: TemplateDELETE,

    SignatureRead: SignatureRead,
    ShowAdministrationRead: ShowAdministrationRead,

    AnalyticsRead: AnalyticsRead,
    AnalyticsManage: AnalyticsManage,
    AnalyticsDelete: AnalyticsDelete

}



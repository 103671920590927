import { TextBox } from "@syncfusion/ej2-react-inputs";
import { Sidebar, TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import React, { PropsWithChildren, useContext, useEffect, useRef } from "react";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { Link } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import RAFViewMoreMenuOptions from "../../../../RAFComponents/RAFViewPanels/RAFViewMoreMenuOptions";
import {
  SetGridState,
  getUserViewsByModuleName,
  isDisableDashboardAnalytic,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  ConvertToElementID,
  IsNotNullOrWhiteSpace,
  deepEqual,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import {
  atomSelectedGridView,
  atomSelectedViewState,
  getDefaultActiveCurrentViewFromList,
  getViewsReportsDashboards,
} from "../../../../RAFModules/Common/List/IndexHelper";
import { WorkspaceLeftItemsContext } from "../../../../RAFModules/Common/Providers/WorkspaceContextProvider";
import {
  PortalTreeViewItemRow,
  convertViewRowToWorkspaceViewItem,
} from "../../../../RAFModules/Common/Workspace/WorkspaceItem/Helper/WorkspaceItemHelper";
import {
  BrowserIsDevice,
  RAFReportType,
  RAFTreeViewMenu,
} from "../../../../constants/Common/Constants";
import { RAFWorkspaceRelatedtoType } from "../../../../constants/Common/RMConstants";
//import { UserFeedsContext } from "../../UserFeedsContextProvider";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { MenuCategory } from "../../../../constants/MenuConstant/RAFMenuCategory";
import { getModuleAvatarIcon } from "../../../../helpers/Common/AvatarHelper";
import "./../../../../styles/NavigationMenu.scss";
import "./NavigationMenuTreeView.scss";
import NavigationMenuTreeViewHeaderContent from "./NavigationMenuTreeViewHeaderContent";

interface IProps {
  theme?: "White" | "Dark";
  mode?: "SideBar" | "FullScreen";
}
function NavigationMenuTreeView({ ...props }: PropsWithChildren<IProps>) {
  const navigationMenuTreeViewHeaderContentRef = useRef(null);

  let treeviewObj: TreeViewComponent;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const workspaceLeftItemsContext = useContext(WorkspaceLeftItemsContext);
  const portalTreeViewItems = isNotNullAndUndefined(workspaceLeftItemsContext)
    ? workspaceLeftItemsContext.portalTreeViewItems
    : [];

  const currentMenuCategory =
    isNotNullAndUndefined(workspaceLeftItemsContext) &&
    isNotNullAndUndefined(workspaceLeftItemsContext.currentMenuCategory)
      ? workspaceLeftItemsContext.currentMenuCategory
      : MenuCategory.Default;

  const leftMenuItemOuterDivKey = isNotEmptyArray(portalTreeViewItems)
    ? `${currentMenuCategory}_${portalTreeViewItems.length}`
    : `${currentMenuCategory}_0`;

  useEffect(() => {
    subscribeRAFEvent(`${RAFEventName.ToggleSideMenu}`, toggleclick);

    return () => {
      unsubscribeRAFEvent(`${RAFEventName.ToggleSideMenu}`, toggleclick);
    };
  });

  let sidebarMenu: Sidebar;

  let width = BrowserIsDevice ? "90%" : "328px";

  sidebarMenu = new Sidebar({
    width: width,
    target: "#mainTabDiv",
    // mediaQuery: '(min-width: 992px)',
    // isOpen: BrowserIsDevice ? false : true,
    isOpen: false,
    enableGestures: false,
    enableDock: true,
    dockSize: "80px",
    //'mouseOver': onmouseover,
    // showBackdrop: BrowserIsDevice ? true : false,
    created: function () {
      // Add mouseover event
      this.element.addEventListener("mouseover", function () {
        if (sidebarMenu && !sidebarMenu.isOpen && sidebarMenu.type === "Over") {
          sidebarMenu.show();
        }
      });

      // Add mouseout event
      this.element.addEventListener("mouseout", function () {
        if (sidebarMenu && sidebarMenu.isOpen && sidebarMenu.type === "Over") {
          sidebarMenu.hide();
        }
      });
    },
  });
  if (BrowserIsDevice) {
    sidebarMenu.enableDock = false;
  }
  // if (!BrowserIsDevice) {
  sidebarMenu.appendTo("#sideTree");
  // }
  let sidebarBackDrop = document.querySelector(".e-sidebar__backdrop");
  if (isNotNullAndUndefined(sidebarBackDrop)) {
    sidebarBackDrop.classList.remove("show");
  }

  //Defines the rendering code blocks for the Textbox component.
  let inputObj: TextBox = new TextBox({
    placeholder: "Search...",
  });
  inputObj.appendTo("#resSearch");

  function toggleclick(backDropClicked?: boolean) {
    let sidebarToggleBtn = document.getElementById("e-sidebar__toggleBtn");
    let sidebarBackDrop = document.querySelector(".e-sidebar__backdrop");
    let sidebar__toggleBtn__collapse = document.querySelector(
      "#btn_e-sidebar__toggleBtn__collapse"
    );
    let sidebar__toggleBtn__expand = document.querySelector(
      "#btn_e-sidebar__toggleBtn__expand"
    );
    console.log();
    if (
      isNotNullAndUndefined(sidebar__toggleBtn__collapse) &&
      isNotNullAndUndefined(sidebar__toggleBtn__expand)
    ) {
      if (sidebarMenu.isOpen === true && sidebarMenu.type === "Over") {
        sidebar__toggleBtn__collapse.classList.remove("hidden");
        sidebar__toggleBtn__expand.classList.add("hidden");
      } else {
        sidebar__toggleBtn__collapse.classList.add("hidden");
        sidebar__toggleBtn__expand.classList.remove("hidden");
      }
    }
    if (sidebarMenu.isOpen === true) {
      isNotNullAndUndefined(sidebarToggleBtn) &&
        sidebarToggleBtn.classList.remove("hidden");
    } else {
      isNotNullAndUndefined(sidebarToggleBtn) &&
        sidebarToggleBtn.classList.add("hidden");
    }
    if (BrowserIsDevice === true && isNotNullAndUndefined(sidebarBackDrop)) {
      if (backDropClicked === true) {
        sidebarBackDrop.classList.remove("show");
        // sidebarMenu.isOpen = false;
        if (
          isNotNullAndUndefined(sidebarMenu) &&
          isNotNullAndUndefined(sidebarMenu.close)
        ) {
          sidebarMenu.close();
        } else {
          sidebarMenu.isOpen = false;
        }
      } else if (sidebarMenu.isOpen === true) {
        sidebarMenu.type = "Over";
        sidebarBackDrop.classList.remove("show");
        // sidebarMenu.isOpen = false;
        if (
          isNotNullAndUndefined(sidebarMenu) &&
          isNotNullAndUndefined(sidebarMenu.close)
        ) {
          sidebarMenu.close();
        } else {
          sidebarMenu.isOpen = false;
        }
      } else {
        sidebarMenu.type = "Auto";
        sidebarBackDrop.classList.add("show");
        // sidebarMenu.isOpen = true;
        if (
          isNotNullAndUndefined(sidebarMenu) &&
          isNotNullAndUndefined(sidebarMenu.open)
        ) {
          sidebarMenu.open();
        } else {
          sidebarMenu.isOpen = true;
        }
      }
    } else {
      if (sidebarMenu.isOpen === true) {
        console.log("Over");
        sidebarMenu.type = "Over";
      } else {
        console.log("Auto");
        sidebarMenu.type = "Auto";
      }
      // sidebarMenu.isOpen = !sidebarMenu.isOpen;
      if (
        isNotNullAndUndefined(sidebarMenu) &&
        isNotNullAndUndefined(sidebarMenu.toggle)
      ) {
        sidebarMenu.toggle();
      } else {
        sidebarMenu.isOpen = !sidebarMenu.isOpen;
      }
    }
  }

  function onClickSidebarToggleBtn() {
    sidebarMenu.toggle();
    sidebarMenu.type = "Auto";
    let sidebarBackDrop = document.querySelector(".e-sidebar__backdrop");
    let sidebar__toggleBtn__collapse = document.querySelector(
      "#btn_e-sidebar__toggleBtn__collapse"
    );
    let sidebar__toggleBtn__expand = document.querySelector(
      "#btn_e-sidebar__toggleBtn__expand"
    );
    if (
      isNotNullAndUndefined(sidebar__toggleBtn__collapse) &&
      isNotNullAndUndefined(sidebar__toggleBtn__expand)
    ) {
      sidebar__toggleBtn__collapse.classList.remove("hidden");
      sidebar__toggleBtn__expand.classList.add("hidden");
    }
    if (BrowserIsDevice === true && isNotNullAndUndefined(sidebarBackDrop)) {
      sidebarBackDrop.classList.remove("show");
    }
  }

  const onClickLeftMenuItem = useRecoilCallback(
    ({ set, snapshot }) =>
      async (item: PortalTreeViewItemRow) => {
        if (isNotNullAndUndefined(item)) {
          //if (item.ItemType === RAFWorkspaceRelatedtoType.View) {
          const prevCurrentView: RAFTreeViewMenu = await snapshot.getPromise(
            atomSelectedViewState(item.Entity)
          );
          const recentViewId =
            item.ItemType === RAFWorkspaceRelatedtoType.View
              ? item.UID
              : isNotNullAndUndefined(prevCurrentView)
              ? prevCurrentView.UID
              : null;

          const recentViewName =
            item.ItemType === RAFWorkspaceRelatedtoType.View
              ? item.Name
              : isNotNullAndUndefined(prevCurrentView)
              ? prevCurrentView.Name
              : null;

          const recentViewType =
            item.ItemType === RAFWorkspaceRelatedtoType.View
              ? null
              : isNotNullAndUndefined(prevCurrentView)
              ? prevCurrentView.Category
              : null;

          const viewMenuDataSource = await getViewsReportsDashboards(
            item.Entity,
            true,
            isDisableDashboardAnalytic(item.Entity),
            null
          );

          const currentView = await getDefaultActiveCurrentViewFromList(
            item.Entity,
            recentViewId,
            recentViewType ?? RAFReportType.Table,
            viewMenuDataSource,
            true,
            permissionValue,
            recentViewName
          );

          if (
            isNotNullAndUndefined(currentView) &&
            !deepEqual(prevCurrentView, currentView)
          ) {
            const currentModule = item.Entity;
            const enableListAndTableView =
              isNotNullAndUndefined(currentModule) &&
              (currentModule === CareEsioEntity.CareRecipient.EntityName ||
                currentModule === RAFEntityName.Team) &&
              isNotNullAndUndefined(currentView) &&
              currentView.Category === RAFReportType.Table
                ? true
                : false;

            let defaultListGridID = `grid3_${currentModule}_mainList`;
            if (enableListAndTableView) {
              const selectedGridViewState = await snapshot.getPromise(
                atomSelectedGridView(currentModule)
              );

              defaultListGridID = `grid3_${currentModule}_mainList${
                selectedGridViewState ?? ""
              }`;
            }

            set(atomSelectedViewState(currentModule), currentView);
            SetGridState(defaultListGridID, null, null);
          }

          if (isNotNullAndUndefined(currentView)) {
            const treeViewItem = getSelectedItemByCurrentView(
              currentView,
              item
            );
            if (isNotNullAndUndefined(treeViewItem)) {
              const currentViewID = treeViewItem.UID ?? treeViewItem.Name;
              updateTreeViewSelectExpandStateByID(currentViewID);
            } else {
              const isLoaded = await loadChildViewItem(item.UID);
              const treeViewItem = getSelectedItemByCurrentView(
                currentView,
                item
              );
              if (isNotNullAndUndefined(treeViewItem)) {
                const currentViewID = treeViewItem.UID ?? treeViewItem.Name;
                updateTreeViewSelectExpandStateByID(currentViewID);
              } else {
                updateTreeViewSelectExpandStateByID(item.UID);
              }
            }
          } else {
            updateTreeViewSelectExpandStateByID(item.UID);
          }
        }
      }
  );

  function nodeTemplate(item: PortalTreeViewItemRow) {
    let fontsize = "1em";
    if (item.Text === "home" || item.Name === "home") {
      fontsize = "1rem";
    }
    const itemType = item.ItemType;
    const displayName = item.Text ?? "na";
    const moduleIconName = getModuleAvatarIcon(
      item.Text === "home" || item.Name === "home" ? "home" : item.Entity
    );

    return (
      <Link
        to={item.URL}
        key={item.URL}
        id={ConvertToElementID("sidebarlist" + displayName)}
        onClick={(e) => {
          let isSelectedMenuItem = false;

          if (
            isNotNullAndUndefined(item.URL) &&
            item.URL.toLowerCase() === window.location.pathname.toLowerCase()
          ) {
            isSelectedMenuItem = true;
          }

          if (isSelectedMenuItem) {
            e.preventDefault();
          }
          onClickLeftMenuItem(item);
          if (
            BrowserIsDevice &&
            (isNotNullAndUndefined(item.ParentUID) ||
              item.HasChildren === false)
          ) {
            toggleclick();
          }
        }}
      >
        <div className={`menu-item-text`}>
          <div className="e-sidebar__treeview__menuItem__iconDiv d-flex align-items-center position-relative">
            {isNotNullAndUndefined(item) &&
            isNotNullAndUndefined(item.IsExternalIcon) &&
            isNullOrUndefined(item.ParentUID) &&
            item.IsExternalIcon === true ? (
              <IconPickerItem
                icon={
                  isNotNullAndUndefined(item) &&
                  isNotNullAndUndefined(item.Icon)
                    ? (item.Icon as IconList)
                    : "FaBriefcase"
                }
                size={20}
                color="inherit"
                containerStyles={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : isNullOrUndefined(item.ParentUID) &&
              IsNotNullOrWhiteSpace(moduleIconName) ? (
              <span
                className={`e-sidebar__treeview__menuItem__icon far fa-${moduleIconName}`}
              ></span>
            ) : (
              <span
                className={
                  item.Name === "home"
                    ? "e-sidebar__treeview__menuItem__icon far fa-" + item.Icon
                    : isNotNullAndUndefined(item.ParentUID)
                    ? "fa-xxsm far fa-circle"
                    : "e-sidebar__treeview__menuItem__icon far fa-" + item.Icon
                }
              ></span>
            )}
          </div>
          <div
            className={`e-sidebar__treeview__menuItem__text ecllipseFirstLine${
              isNotNullAndUndefined(item.ParentUID) ? " ps-0" : ""
            }`}
          >
            {displayName}
          </div>
          {item.Name === "home" ? (
            <></>
          ) : item.Name === "inbox" ? (
            <div className="ms-2">
              <TooltipComponent content={"Under beta testing"}>
                <div className="raf_badge raf_18 raf_badge_rgba content_neutral_white raf_badge_beta">
                  <span>βeta</span>
                </div>
              </TooltipComponent>
            </div>
          ) : (
            (item.ItemType === RAFWorkspaceRelatedtoType.View ||
              item.ItemType === RAFWorkspaceRelatedtoType.Module) &&
            isNotNullAndUndefined(item.ParentUID) &&
            !BrowserIsDevice && (
              <div className="ms-auto">
                <RAFViewMoreMenuOptions
                  viewID={item.UID}
                  moduleName={item.Entity}
                  onSave={onChangeViewRow}
                  itemType={itemType}
                />
              </div>
            )
          )}
        </div>
      </Link>
    );
  }

  const nodeExpanding = async (args) => {
    if (isNotNullAndUndefined(args) && args.isInteracted == true) {
      const nodeDataID = isNotNullAndUndefined(args.nodeData)
        ? args.nodeData.id
        : null;
      if (isNotNullAndUndefined(nodeDataID)) {
        const isLoaded = await loadChildViewItem(nodeDataID);
        if (isNotNullAndUndefined(treeviewObj)) {
          const selectedNodes = treeviewObj.selectedNodes;
          if (isNotEmptyArray(selectedNodes)) {
            updateTreeViewSelectExpandStateByID(selectedNodes[0]);
          }
        }
      }
    }
  };

  const updateTreeViewSelectExpandStateByID = (nodeId: string) => {
    if (isNotNullAndUndefined(treeviewObj) && isNotNullAndUndefined(nodeId)) {
      const currentNode = getTreeViewNodeDataByNodeID(nodeId);
      const treeSelectedNodeID = isNotEmptyArray(treeviewObj.selectedNodes)
        ? treeviewObj.selectedNodes[0]
        : null;
      const treeExpandedNodesID = isNotEmptyArray(treeviewObj.expandedNodes)
        ? treeviewObj.expandedNodes[0]
        : null;
      if (isNotNullAndUndefined(currentNode)) {
        const hasChildren = isNotEmptyArray(currentNode.NodeChild)
          ? true
          : false;
        if (hasChildren === true) {
          if (treeExpandedNodesID !== currentNode.UID) {
            treeviewObj.expandedNodes = [currentNode.UID];
          }
        } else if (isNotNullAndUndefined(currentNode.ParentUID)) {
          if (treeExpandedNodesID !== currentNode.ParentUID) {
            treeviewObj.expandedNodes = [currentNode.ParentUID];
          }
        }
        treeviewObj.selectedNodes = [currentNode.UID];
        if (
          isNotNullAndUndefined(navigationMenuTreeViewHeaderContentRef) &&
          isNotNullAndUndefined(navigationMenuTreeViewHeaderContentRef.current)
        ) {
          const currentPath = window.location.pathname.toLowerCase();
          navigationMenuTreeViewHeaderContentRef.current.updateTreeViewSelectExpandStateByID(
            currentNode.UID,
            currentPath
          );
        }
      }
    }
  };

  const loadChildViewItem = (selectedNodeId: string) => {
    return new Promise<boolean>(async (resolve) => {
      let isLoaded = false;
      if (
        isNotNullAndUndefined(selectedNodeId) &&
        isNotNullAndUndefined(treeviewObj)
      ) {
        const allNodeDate: PortalTreeViewItemRow[] =
          treeviewObj.getTreeData() as any;
        const currentNode = getTreeViewNodeDataByNodeID(selectedNodeId);
        if (
          isNotNullAndUndefined(currentNode) &&
          isNotNullAndUndefined(currentNode.Entity) &&
          currentNode.HasChildren === true &&
          currentNode.ItemType === RAFWorkspaceRelatedtoType.Module
        ) {
          const currentNodeChildItems = isNotNullAndUndefined(currentNode)
            ? currentNode.NodeChild
            : [];
          const childItemInTree: PortalTreeViewItemRow[] = isNotEmptyArray(
            allNodeDate
          )
            ? allNodeDate.filter((x) => x.ParentUID === selectedNodeId)
            : [];
          const isChildExist =
            isNotEmptyArray(childItemInTree) ||
            isNotEmptyArray(currentNodeChildItems)
              ? true
              : false;
          if (isChildExist === false) {
            const selectedWorkspaceItem = portalTreeViewItems.find(
              (x) => x.UID === selectedNodeId
            );
            const entity = selectedWorkspaceItem.Entity;
            if (isNotNullAndUndefined(entity)) {
              const viewItems = await getUserViewsByModuleName(entity, true);
              const viewMenusRow = convertViewRowToWorkspaceViewItem(
                viewItems,
                entity,
                selectedWorkspaceItem.UID,
                selectedWorkspaceItem.URL,
                permissionValue
              );
              if (
                isNotEmptyArray(viewMenusRow) &&
                isNotNullAndUndefined(treeviewObj)
              ) {
                treeviewObj.addNodes(
                  viewMenusRow as any,
                  selectedNodeId.toString()
                );
                isLoaded = true;
              }
            }
          }
        }
      }
      resolve(isLoaded);
    });
  };

  const onTreeViewDataBound = () => {
    setTimeout(() => {
      if (isNotEmptyArray(portalTreeViewItems)) {
        const selectedItem = getSelectedItemAndParentId();
        if (isNotNullAndUndefined(selectedItem)) {
          onClickLeftMenuItem(selectedItem);
        }
      }
    }, 100);
  };

  const getTreeViewNodeDataByNodeID = (nodeId: string) => {
    let selectedItem: PortalTreeViewItemRow = null;
    if (isNotNullAndUndefined(treeviewObj)) {
      const allNodeDate: PortalTreeViewItemRow[] =
        treeviewObj.getTreeData() as any;
      if (isNotEmptyArray(allNodeDate) && isNotNullAndUndefined(nodeId)) {
        allNodeDate.forEach((item) => {
          if (item.UID === nodeId) {
            selectedItem = item;
          } else if (isNotEmptyArray(item.NodeChild)) {
            item.NodeChild.forEach((childItem) => {
              if (childItem.UID === nodeId) {
                selectedItem = childItem;
              }
            });
          }
        });
      }
    }
    return selectedItem;
  };

  const getSelectedItemByCurrentView = (
    currentView: RAFTreeViewMenu,
    item: PortalTreeViewItemRow
  ) => {
    let selectedItem: PortalTreeViewItemRow = null;
    if (isNotNullAndUndefined(treeviewObj)) {
      const allNodeDate: PortalTreeViewItemRow[] =
        treeviewObj.getTreeData() as any;
      if (isNotEmptyArray(allNodeDate) && isNotNullAndUndefined(currentView)) {
        const currentViewID =
          currentView.UID ?? `${item.Entity}_${currentView.Name}`;
        allNodeDate.forEach((item) => {
          if (item.UID === currentViewID) {
            selectedItem = item;
          } else if (isNotEmptyArray(item.NodeChild)) {
            item.NodeChild.forEach((childItem) => {
              if (childItem.UID === currentViewID) {
                selectedItem = childItem;
              }
            });
          }
        });
      }
    }
    return selectedItem;
  };

  const getSelectedItemAndParentId = () => {
    const currentPath = window.location.pathname.toLowerCase();
    let selectedItem: PortalTreeViewItemRow = null;
    if (isNotEmptyArray(portalTreeViewItems)) {
      portalTreeViewItems.forEach((item) => {
        if (
          isNotNullAndUndefined(currentPath) &&
          isNotNullAndUndefined(item.URL) &&
          item.URL.toLowerCase() === currentPath.toLowerCase()
        ) {
          selectedItem = item;
        } else if (isNotEmptyArray(item.NodeChild)) {
          item.NodeChild.forEach((childItem) => {
            if (
              isNotNullAndUndefined(currentPath) &&
              isNotNullAndUndefined(childItem.URL) &&
              childItem.URL.toLowerCase() === currentPath.toLowerCase()
            ) {
              selectedItem = childItem;
            }
          });
        }
      });
    }
    return selectedItem;
  };

  const onChangeViewRow = (recentViewId: string, itemEntity: string) => {
    workspaceLeftItemsContext.updateCurrentWorkspaceLeftMenuItems(
      recentViewId,
      itemEntity
    );
  };

  return (
    <>
      <aside
        id="sideTree"
        className={`sidebar-treeview e-sidebar__treeview section__container${
          props.theme === "White" ? " whiteTheme" : ""
        }`}
      >
        <NavigationMenuTreeViewHeaderContent
          ref={navigationMenuTreeViewHeaderContentRef}
          theme={props.theme}
          onClickSidebarToggleBtn={onClickSidebarToggleBtn}
          toggleclick={toggleclick}
          activeCurrentPathUpdated={onTreeViewDataBound}
        />
        <div
          id="mainTree"
          className="section__div customScrollBar"
          key={leftMenuItemOuterDivKey}
        >
          <TreeViewComponent
            ref={(treeview) => (treeviewObj = treeview)}
            fields={{
              dataSource: portalTreeViewItems as any,
              id: propertyOf<PortalTreeViewItemRow>("UID"),
              parentID: propertyOf<PortalTreeViewItemRow>("ParentUID"),
              hasChildren: propertyOf<PortalTreeViewItemRow>("HasChildren"),
              text: propertyOf<PortalTreeViewItemRow>("Text"),
              child: propertyOf<PortalTreeViewItemRow>("NodeChild"),
              iconCss: propertyOf<PortalTreeViewItemRow>("Icon"),
            }}
            nodeTemplate={nodeTemplate.bind(this)}
            nodeExpanding={nodeExpanding}
            //nodeSelected={onNodeSelect}
            loadOnDemand={true}
            dataBound={onTreeViewDataBound}
            enablePersistence
          />
        </div>
      </aside>
      <div
        className="e-sidebar__backdrop"
        onClick={() => toggleclick(true)}
      ></div>
    </>
  );
}
export default React.memo(NavigationMenuTreeView);
